export const IMAGES = [
  {
    src: "/images/Elegidas maceo/20190401_165000.jpg",
    thumbnail: "/images/Elegidas maceo/20190401_165000.jpg",
    thumbnailWidth: 400,
    thumbnailHeight: 400,
  },
  {
    src: "/images/Elegidas maceo/20200823_113904.jpg",
    thumbnail: "/images/Elegidas maceo/20200823_113904.jpg",
    thumbnailWidth: 500,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/20200823_104333.jpg",
    thumbnail: "/images/Elegidas maceo/20200823_104333.jpg",
    thumbnailWidth: 400,
    thumbnailHeight: 200,
    // tags: [
    //   { value: "Ocean", title: "Ocean" },
    //   { value: "People", title: "People" },
    // ],
  },
  {
    src: "/images/Elegidas maceo/20200823_175557.jpg",
    thumbnail: "/images/Elegidas maceo/20200823_175557.jpg",
    thumbnailWidth: 500,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/20200823_195147.jpg",
    thumbnail: "/images/Elegidas maceo/20200823_195147.jpg",
    thumbnailWidth: 500,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/20200825_131630.jpg",
    thumbnail: "/images/Elegidas maceo/20200825_131630.jpg",
    thumbnailWidth: 500,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/20200827_112130.jpg",
    thumbnail: "/images/Elegidas maceo/20200827_112130.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/20200830_194507.jpg",
    thumbnail: "/images/Elegidas maceo/20200830_194507.jpg",
    thumbnailWidth: 600,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/20200831_105749.jpg",
    thumbnail: "/images/Elegidas maceo/20200831_105749.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/20200831_133422.jpg",
    thumbnail: "/images/Elegidas maceo/20200831_133422.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/20200831_192035.jpg",
    thumbnail: "/images/Elegidas maceo/20200831_192035.jpg",
    thumbnailWidth: 400,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/20200831_221314.jpg",
    thumbnail: "/images/Elegidas maceo/20200831_221314.jpg",
    thumbnailWidth: 400,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/20200901_083523.jpg",
    thumbnail: "/images/Elegidas maceo/20200901_083523.jpg",
    thumbnailWidth: 650,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/DJI_0001.JPG",
    thumbnail: "/images/Elegidas maceo/DJI_0001.JPG",
    thumbnailWidth: 650,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/DJI_0002.JPG",
    thumbnail: "/images/Elegidas maceo/DJI_0002.JPG",
    thumbnailWidth: 650,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/IMG-20190328-WA0030.jpg",
    thumbnail: "/images/Elegidas maceo/IMG-20190328-WA0030.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/IMG-20190328-WA0032.jpg",
    thumbnail: "/images/Elegidas maceo/IMG-20190328-WA0032.jpg",
    thumbnailWidth: 300,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/IMG-20190328-WA0034.jpg",
    thumbnail: "/images/Elegidas maceo/IMG-20190328-WA0034.jpg",
    thumbnailWidth: 500,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/IMG-20190328-WA0033.jpg",
    thumbnail: "/images/Elegidas maceo/IMG-20190328-WA0033.jpg",
    thumbnailWidth: 290,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/IMG-20190328-WA0053.jpg",
    thumbnail: "/images/Elegidas maceo/IMG-20190328-WA0053.jpg",
    thumbnailWidth: 290,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/IMG-20190328-WA0077.jpg",
    thumbnail: "/images/Elegidas maceo/IMG-20190328-WA0077.jpg",
    thumbnailWidth: 500,
    thumbnailHeight: 212,
  },
  {
    src: "/images/Elegidas maceo/IMG-20190328-WA0068.jpg",
    thumbnail: "/images/Elegidas maceo/IMG-20190328-WA0068.jpg",
    thumbnailWidth: 500,
    thumbnailHeight: 212,
  },
];
